import type { EntityObject } from "./SceneElementsTypes";
import { baseURLForAssets } from "./signals";

function absoluteURL(baseURL: string, value: string) {
  if (value.length > 0 && value[0] !== "/" && !value.startsWith("http")) {
    value = `${baseURL}${value}`;
  }
  return value;
}

export function absoluteURLForAsset(value: string | undefined) {
  if (!value) return "";
  const baseURL = baseURLForAssets();
  // Remove non existing hubs domain anymore, try to get the file locally
  value = value.replace("https://uploads-prod.reticulum.io/files/", "");
  return absoluteURL(baseURL, value);
}

export function relativeURLForAsset(value: string | undefined) {
  if (!value) return "";
  return value.replaceAll(baseURLForAssets(), "");
}

export function transformComponentValueToUseAbsoluteURLForAsset(componentName, componentValue) {
  if (componentName === "load360onclick" || componentName === "gltf-model") {
    componentValue = absoluteURLForAsset(componentValue);
    return componentValue;
  }

  let objectCopyDone = false;
  if (
    typeof componentValue === "string" &&
    (componentValue.indexOf("src:") > -1 ||
      componentValue.indexOf("texture:") > -1 ||
      componentValue.indexOf("map:") > -1)
  ) {
    componentValue = AFRAME.utils.styleParser.parse(componentValue);
    objectCopyDone = true;
  }

  if (typeof componentValue === "object") {
    if (!objectCopyDone) {
      componentValue = { ...componentValue };
    }
    if (componentValue.src) {
      componentValue.src = absoluteURLForAsset(componentValue.src);
    }
    if (componentValue.texture) {
      // particles component
      componentValue.texture = absoluteURLForAsset(componentValue.texture);
    }
    if (componentValue.map) {
      componentValue.map = absoluteURLForAsset(componentValue.map);
    }
  }

  return componentValue;
}

export function transformComponentValueToUseRelativeURLForAsset(componentName, componentValue) {
  if (componentName === "load360onclick" || componentName === "gltf-model") {
    componentValue = relativeURLForAsset(componentValue);
    return componentValue;
  }

  if (
    typeof componentValue === "string" &&
    (componentValue.indexOf("src:") > -1 ||
      componentValue.indexOf("texture:") > -1 ||
      componentValue.indexOf("map:") > -1)
  ) {
    componentValue = relativeURLForAsset(componentValue);
  }

  if (typeof componentValue === "object") {
    componentValue = { ...componentValue };
    if (componentValue.src) {
      componentValue.src = relativeURLForAsset(componentValue.src);
    }
    if (componentValue.texture) {
      // particles component
      componentValue.texture = relativeURLForAsset(componentValue.texture);
    }
    if (componentValue.map) {
      componentValue.map = relativeURLForAsset(componentValue.map);
    }
  }

  return componentValue;
}

export const recursiveInPlaceTransformToUseRelativeURL = (entityObj: EntityObject) => {
  if (entityObj.components) {
    for (let [componentName, componentValue] of Object.entries(entityObj.components)) {
      componentValue = transformComponentValueToUseRelativeURLForAsset(componentName, componentValue);
      entityObj.components[componentName] = componentValue;
    }
  }
  if (entityObj.children) {
    for (const child of entityObj.children) {
      recursiveInPlaceTransformToUseRelativeURL(child);
    }
  }
};

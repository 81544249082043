import { MetaProvider } from "@solidjs/meta";
import { render } from "solid-js/web";
import { QueryClient, QueryClientProvider } from "@tanstack/solid-query";
import { SolidQueryDevtools } from "@tanstack/solid-query-devtools";
import App from "./App";

const queryClient = new QueryClient();

const solidRoot = document.getElementById("root")!;
render(
  () => (
    <MetaProvider>
      <QueryClientProvider client={queryClient}>
        <SolidQueryDevtools />
        <App />
      </QueryClientProvider>
    </MetaProvider>
  ),
  solidRoot
);

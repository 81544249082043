import { useParams } from "@solidjs/router";
import { createResource } from "solid-js";
import { setBaseURLForAssets } from "./signals";
import type { SceneElements } from "./SceneElementsTypes";
export { absoluteURLForAsset } from "./urlUtils";

function getBaseURL(sceneElements: SceneElements, basePath: string) {
  let baseURL;
  if (basePath.endsWith("/")) basePath = basePath.slice(0, -1);
  if (sceneElements.baseURL) {
    baseURL = sceneElements.baseURL;
    if (baseURL === "../..") {
      baseURL = basePath.split("/").slice(0, -2).join("/");
    }
  } else {
    baseURL = basePath;
  }
  if (!baseURL.endsWith("/")) baseURL += "/";
  return baseURL;
}

export const fetchSceneElements = async (sceneName: string, tenantId: string | undefined): Promise<SceneElements> => {
  let scene;
  if (tenantId) {
    const url = `/tenants/${tenantId}/config.json`;
    const res = await fetch(url);
    if (res.status === 404) {
      throw new Error(`notfound ${url}`);
    }
    const scenesJson: {
      scenes: {
        visible: boolean;
        title: string;
        sceneName: string;
        preview: string;
      }[];
    } = await res.json();
    scene = scenesJson.scenes.find((s) => s.sceneName === sceneName);
    if (!scene) {
      throw new Error(`notfound ${sceneName}`);
    }
  }

  let basePath;
  if (tenantId && scene) {
    basePath = `/scenes/${tenantId}/scenes/${scene.sceneName}/`;
  } else {
    basePath = `/scenes/${sceneName}/`;
  }
  const url = `${basePath}elements.json`;
  const res = await fetch(url);
  if (res.status === 404) {
    throw new Error(`notfound ${url}`);
  }
  const sceneElements = await res.json();
  const baseURL = getBaseURL(sceneElements, basePath);
  setBaseURLForAssets(baseURL);
  return sceneElements;
};

export const routeData = () => {
  const params = useParams(); // eslint-disable-line
  const [sceneElements] = createResource<SceneElements>(async () => {
    return await fetchSceneElements(params.sceneName, params.tenantId);
  });
  return sceneElements;
};

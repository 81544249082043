import { Workbox } from "workbox-window";
import { jwtDecode } from "jwt-decode";
import { Title } from "@solidjs/meta";
import { Routes, Route, Router } from "@solidjs/router";
import { createEffect, createResource, createSignal, lazy, onCleanup } from "solid-js";
import { createI18n, I18nProvider } from "./i18n";
import type { SceneInfo } from "./TenantTypes";
import French from "../locales/fr.json";
import { createStore, reconcile } from "solid-js/store";
import { supabase } from "./supabaseClient";
import AppNeedsUpdate, { appNeedsUpdateDismiss, appNeedsUpdateOk, setAppNeedsUpdate } from "./AppNeedsUpdate";
import { routeData as RoomData } from "./Room.data";
import { routeData as SceneData } from "./Scene.data";
import {
  appName,
  pseudo,
  setPseudoWithoutDollar,
  setSession,
  setUserCanSeeTenantManageScreens,
  setUserIsAdmin,
  setUserIsTenantAdmin,
  setUserTenantId,
  tenantId,
  userIsAdmin,
  userIsTenantAdmin,
  userTenantId,
} from "./signals";

const Home = lazy(() => import("./Home"));
const ARPage = lazy(() => import("./ARPage"));
const MVPage = lazy(() => import("./MVPage"));
const ARBanner = lazy(() => import("./ARBanner"));
const Room = lazy(() => import("./Room"));
const TenantHome = lazy(() => import("./pages/TenantHome"));
const Search = lazy(() => import("./pages/Search"));
const Configurator = lazy(() => import("./pages/Configurator"));
const SimpleEditor = lazy(() => import("./pages/SimpleEditor"));
const Login = lazy(() => import("./pages/Login"));
const Profile = lazy(() => import("./pages/Profile"));
const SavedRooms = lazy(() => import("./pages/SavedRooms"));

const languageLabels = new Map([
  ["en", "English"],
  ["fr", "Français"],
  ["ko", "한국어"],
]);
const supportedLanguages = ["en", "fr"];

let lang = "en";
let savedLang = localStorage.getItem("lang");
if (savedLang !== null && supportedLanguages.indexOf(savedLang) === -1) {
  savedLang = null;
}
if (savedLang === null) {
  const locale = navigator.language;
  if (locale.startsWith("fr")) {
    lang = "fr";
  }
} else {
  lang = savedLang;
}

const [language, setLanguage] = createSignal(lang);
export { language, setLanguage, supportedLanguages, languageLabels };

export const i18nCatalog = createI18n({ language: lang, locales: { fr: French } });
// to change dynamically the language:
// i18n.setLanguage("fr");
// to set the messages of the current set language (if we load the json async):
// i18n.setLocales(French);

const fetchScenes = async () => {
  const res = await fetch("/scenes.json");
  return res.json();
};

const [scenes, setScenes] = createStore<Array<SceneInfo>>([]);
export { scenes };

const [data, { mutate, refetch }] = createResource<{ scenes: Array<SceneInfo> }>(fetchScenes); // eslint-disable-line

const App = () => {
  createEffect(() => {
    const dataJson = data();
    if (dataJson) {
      setScenes(dataJson.scenes);
    }
  });

  createEffect(() => {
    const lang = language();
    i18nCatalog.setLanguage(lang);
    localStorage.setItem("lang", lang);
    document.querySelector("html").setAttribute("lang", lang);
  });

  createEffect(() => {
    setPseudoWithoutDollar(pseudo().replace("$", ""));
  });

  createEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(reconcile(session ?? { user: null }));
    });
    const { data } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(reconcile(session ?? { user: null }));
      if (session) {
        const jwt = jwtDecode(session.access_token);
        // @ts-ignore
        const userRole = jwt.user_role;
        // @ts-ignore
        const tenantId = jwt.tenant_id;
        setUserIsAdmin(userRole === "admin");
        setUserIsTenantAdmin(userRole === "tenant_admin");
        setUserTenantId(tenantId ?? "");
      }
    });
    onCleanup(() => {
      data.subscription.unsubscribe();
    });
  });

  createEffect(() => {
    setUserCanSeeTenantManageScreens(
      (userIsTenantAdmin() && tenantId() && tenantId() === userTenantId()) || userIsAdmin()
    );
  });

  return (
    <I18nProvider i18n={i18nCatalog}>
      <Title>{appName()}</Title>
      <AppNeedsUpdate />
      {/* <ErrorBoundary fallback={<div>Something went terribly wrong</div>}> */}
      <Router>
        <Routes>
          <Route path="/" component={Home} />
          <Route path="/r/:roomId/:sceneName?" component={Room} data={RoomData} />
          <Route path="/r/:roomId/:sceneName/ar" component={ARPage} data={SceneData} />
          <Route path="/r/:roomId/:sceneName/mv" component={MVPage} data={SceneData} />
          <Route path="/r/:roomId/:sceneName/editor" component={SimpleEditor} />
          <Route path="/r/:roomId/:tenantId/:sceneName" component={Room} data={RoomData} />
          <Route path="/r/:roomId/:tenantId/:sceneName/editor" component={SimpleEditor} />
          <Route path="/r/:roomId/:tenantId/:sceneName/ar" component={ARPage} data={SceneData} />
          <Route path="/r/:roomId/:sceneName/arbanner" component={ARBanner} data={SceneData} />
          <Route path="/go/:roomId" component={Room} data={RoomData} />
          <Route path="/configurator" component={Configurator} />
          <Route path="/login" component={Login} />
          <Route path="/profile" component={Profile} />
          <Route path="/:tenantId" component={TenantHome} />
          <Route path="/:tenantId/search" component={Search} />
          <Route path="/:tenantId/saved-rooms" component={SavedRooms} />
        </Routes>
      </Router>
      {/* </ErrorBoundary> */}
    </I18nProvider>
  );
};

export default App;

// This code sample uses features introduced in Workbox v6.
const promptForUpdate = () => {
  return new Promise((resolve) => {
    setAppNeedsUpdate(true);
    createEffect(() => {
      if (appNeedsUpdateOk()) {
        resolve(true);
      }
      if (appNeedsUpdateDismiss()) {
        resolve(false);
      }
    });
  });
};

if (process.env.NODE_ENV === "production") {
  if ("serviceWorker" in navigator) {
    const wb = new Workbox("/service-worker.js");

    // @ts-ignore
    const showSkipWaitingPrompt = async (event) => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      wb.addEventListener("controlling", () => {
        // At this point, reloading will ensure that the current
        // tab is loaded under the control of the new service worker.
        // Depending on your web app, you may want to auto-save or
        // persist transient state before triggering the reload.
        window.location.reload();
      });

      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      // This code assumes your app has a promptForUpdate() method,
      // which returns true if the user wants to update.
      // Implementing this is app-specific; some examples are:
      // https://open-ui.org/components/alert.research or
      // https://open-ui.org/components/toast.research
      const updateAccepted = await promptForUpdate();

      if (updateAccepted) {
        wb.messageSkipWaiting();
      }
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener("waiting", (event) => {
      showSkipWaitingPrompt(event);
    });

    wb.register();
  }
}
